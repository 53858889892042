import {getClientMap} from "./helper";

onGigyaServiceReady = (function (existingGSR) {
  return (function () {

    // check for gigya dependencies
    !function (a, b) {
      a = "[" + a + "]", b && void 0 === window.gigya && console.error(a + " The gigya object does not exist."), console.log(a + " Loading..."), "function" == typeof existingGSR && (console.log(a + "... Executing prior onGigyaServiceReady functions"), existingGSR(), console.log(a + "... Now continuing with this onGigyaServiceReady"))
    }("authnLocal.js", !0)

    if (typeof gigya._myContext == 'undefined' || typeof gigya._myContext.authn == 'undefined') {
      console.warn('[authnLocal.js] This package depends on authn.js, which has not yet been loaded.');
    }


    // requesting clients are mapped to a business purpose; info arrives as request parameter
    _clientMap = getClientMap();

    gigya.accounts.addEventHandlers({
      onLogin: function (eventObject) {

        _client = gigya._myContext.authn.context.get()['client'] || 'Direct IdP Login';

        console.log("Current Client: ", _client + ' => ' + _clientMap[_client]);
        console.log(eventObject.eventName + " called:\n" + JSON.stringify(eventObject, null, 4));

        // requesting clients require certain attributes
        var missingAttributes = (function (client, eventObject) {
          var requiredAttributes = { // list all required attributes for all supported clients
            'MiltenyiUniversity': [
              'profile.zip',
              'profile.city'
            ],
            'pickup': [
              'data.service.pickUp.alias',
            ],
            'psp': [
              'data.service.psp.instrumentSerialNum',
              'data.service.psp.instrumentType',
            ],
            'DashboardWebshop': []
          };

          var missingAttributes = []; // return an array of all missing attributes
          if (client && requiredAttributes[client]) {
            for (ii = requiredAttributes[client].length - 1; ii >= 0; ii--) {

              // dynamically create a function to check that attribute exists for the requesting client
              attributeExists = (function (eventObject, attribute) {
                o = JSON.parse(eventObject);
                return ( // search for attribute that may be multiple layers deep
                  new Function("return function (){ \
											try { \
												return((o." + attribute + ") ? true : false); \
											} catch(e) { return(false); } \
										}")()
                );
              })(JSON.stringify(eventObject), requiredAttributes[client][ii]);

              if (!attributeExists()) { // add it to the response
                missingAttributes.unshift(requiredAttributes[client][ii]);
              }

            }
          }

          console.log("missingAttributes: " + missingAttributes);
          return missingAttributes;
        })(_clientMap[_client], eventObject);


        if (missingAttributes.length > 0) { // collect required attributes
          // if (gigya._myContext.authn.useRedirects) {
          window.location = 'register.html?incomplete&client=mu';
          // } else {
          // 	// need client name available for visibility logic in reg completion screen
          // 	gigya._myContext.client = _clientMap[_client];
          //
          // 	var showScreenSetParams = {
          // 			containerID:'login'
          // 			,screenSet:'MB-RegistrationLogin'
          // 			,startScreen: 'gigya-complete-registration-screen'
          // 			,context: { properLogin: eventObject }
          // 			,onAfterScreenLoad: function(e){
          // 				document.dispatchEvent(new CustomEvent("gigyaEvent", { detail: { event: "changeScreen", reason: "Need to capture more user detail for "+_client+' => '+_clientMap[_client], triggeringEvent: e } }
          // 					));
          // 			}
          // 	};
          //
          // 	gigya.accounts.showScreenSet(showScreenSetParams);
          // }
        } else { // IdP authentication complete; return to requesting client
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'authentication',
            'userID': eventObject.UID,
          });
          localStorage.setItem('GA_userID', eventObject.UID)
          gigya._myContext.authn.context.finalizeLogin() && console.log('*** authn finalized!');
        }
      }
    });
  });
})((typeof onGigyaServiceReady === 'undefined') ? null : onGigyaServiceReady);
