export function includeScript(uri, inner, asModule = false) {
  const jsElm = document.createElement("script");
  if (asModule)
    jsElm.type = "module";
  jsElm.src = uri;
  if (inner) {
    jsElm.innerHTML = inner
  }
  document.body.appendChild(jsElm);
}

export function includeOIDCProxyScripts() {
  const loginUrl = `${process.env.HOSTINGBASE}/index.html?init=1&oidc&redirectURL=${window.location.origin}${window.location.pathname}`
  const consentUrl = `${process.env.HOSTINGBASE}/OIDC/consent.html`
  const errorUrl = `${process.env.HOSTINGBASE}/OIDC/error.html`
  const inner = {
    loginURL: loginUrl,
    consentURL: consentUrl,
    errorURL: errorUrl
  };

  includeScript(`https://cdns.gigya.com/JS/gigya.js?apiKey=${process.env.APIKEY}`)
  includeScript(`https://cdns.gigya.com/JS/gigya.oidc.js?apiKey=${process.env.APIKEY}`, JSON.stringify(inner))
}

export function includeOIDCConcentScripts() {
  includeScript(`https://cdns.gigya.com/JS/gigya.js?apikey=${process.env.APIKEY}`)
}

export function includeSAMLProxyScripts() {
  const loginURL = `${process.env.HOSTINGBASE}?init=1&spName=\${spName}`
  const logoutURL = `${process.env.HOSTINGBASE}?init=1&spName=\${spName}/SAML/logout.html`
  const inner = {
    loginURL: loginURL,
    logoutURL: logoutURL
  };

  includeScript(`https://cdns.gigya.com/js/gigya.saml.js?apiKey=${process.env.APIKEY}`, JSON.stringify(inner))
}

export function includeSAMLLogoutScripts() {
  includeScript(`http://cdn.gigya.com/JS/gigya.js?apiKey=${process.env.APIKEY}`)
}

export function getClientMap() {
  let clientMapVars = process.env.CLIENTMAP;
  return JSON.parse(clientMapVars);
}

export function getURLParam(param) {
  return gigya.utils.URL.getParamsFromURL(document.URL)[param];
}